import React, { useEffect, useState } from "react";
import { useDrag } from "react-use-gesture";
import { useSpring, animated } from "@react-spring/web";
import { AnimatePresence, motion } from "framer-motion";

export interface EnvelopeProps {
	children: string;
}

const Envelope = () => {
	const [lastPosition, setLastPosition] = React.useState(0);
	const [selectedId, setSelectedId] = useState<string | null>(null);

	const [{ rotateX }, api] = useSpring(() => ({
		rotateX: 0,
	}));

	const [props, set] = useSpring(() => ({
		transform: `scale(0) rotate(0deg)`,
		from: {
			transform: `scale(0) rotate(-18deg)`,
		},
		config: { mass: 1, tension: 170, friction: 18, velocity: 0.8 },
	}));

	useEffect(() => {
		setTimeout(() => {
			set({
				transform: `scale(1) rotate(0deg)`,
			});
		}, 500);
	}, []);

	const bind = useDrag(({ active, movement: [x, y] }) => {
		api.start({
			rotateX: y,
			immediate: (name) => {
				const isImmediate = active && name === "rotateX";

				if (!isImmediate) {
					setLastPosition(y);
				}

				return active && name === "rotateX";
			},
		});
	});

	return (
		<>
			<AnimatePresence>
				{selectedId && (
					<>
						<motion.div className="letter letter--open" layoutId={selectedId}>
							<motion.h2>Hello bubsi :)</motion.h2>
							<motion.p>
								First of all I want to say how lucky i am to have you and i want
								to promise you that this is the first and last Valentines day we
								will spend apart, I promise you that no matter how far apart we
								may be I'll always find a way to you.
								<br />
								<br />
								Second! I love sending you emails, but i thought it may be nice
								to add a little bit of a personal touch to it, so from now on
								I'll be sending my letters here and don't worry I'll make sure
								you're the only one that can see them :)
								<br />
								<br />I hope you and Aleksandra and your mama like the flowers i
								got you and i hope you have a wonderful day and i hope i can see
								you even if it's for a little while and we can have a little
								long distance date :)
							</motion.p>
							<motion.div className="author-wrapper">
								<div>
									<motion.p style={{ margin: 0 }}>Love Yours</motion.p>
									<motion.p style={{ marginTop: 10 }}>Luka {"<3"}</motion.p>
								</div>
							</motion.div>
						</motion.div>
						<div className="modal-backdrop"></div>
					</>
				)}
			</AnimatePresence>
			<animated.div className="envelope" style={props}>
				<div className="envelope--inner">
					<motion.div
						className="letter"
						layoutId={"letter-1"}
						onClick={() => setSelectedId("letter-1")}
					>
						<motion.p>
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde
							possimus ipsam molestiae ullam commodi reprehenderit illum
							doloribus, eveniet enim veritatis quo optio voluptatum quas
							officiis distinctio. Error quae architecto culpa!
						</motion.p>
					</motion.div>
				</div>
				<div className="envelope--back"></div>
				<div className="envelope--right"></div>
				<div className="envelope--left"></div>
				<div className="envelope--bottom--wrapper">
					<div className="envelope--bottom"></div>
				</div>
				<animated.div
					{...bind()}
					className="envelope--top--wrapper"
					style={{
						transform: rotateX.to((r) => {
							console.log(rotateX.toJSON());
							let amount = (r + lastPosition) * -1;
							console.log("amount", amount);

							if (amount > 180) {
								amount = 180;
							}

							if (amount < 0) {
								amount = 0;
							}

							return `rotateX(${amount}deg)`;
						}),
					}}
				>
					<animated.div className="envelope--top"></animated.div>
				</animated.div>
			</animated.div>
		</>
	);
};

export default Envelope;
