import React, { useEffect } from "react";
import OTPInput from "react-otp-input";
import { Slider } from "./Slide";
import JSConfetti from "js-confetti";
import { IPanelProps } from "./types";
import Hide from "./Hide";

const Lock = ({ nextPage }: IPanelProps) => {
	const [otp, setOtp] = React.useState("");
	const [error, setError] = React.useState(false);
	const [isOpen, setIsOpen] = React.useState(false);

	const checkOtp = (otpToCheck: string) => {
		if (otpToCheck.toLowerCase() === "cookies") {
			const jsConfetti = new JSConfetti();

			// different emojis
			jsConfetti.addConfetti({
				emojis: ["🌈", "⚡️", "💥", "✨", "💫", "🌸", "🍪", "🤍"],
				confettiRadius: 100,
				confettiNumber: 69,
			});

			setIsOpen(true);
			return;
		}

		setError(true);
	};

	const handleSliderClick = () => {
		if (isOpen) {
			nextPage();
		}
	};

	useEffect(() => {
		setError(false);
		if (otp.length === 7) {
			checkOtp(otp);
		}
	}, [otp]);

	return (
		<div className="lock">
			<div className="lock-inner">
				{isOpen ? (
					<Hide>
						<h1>Welcome :)</h1>
					</Hide>
				) : (
					<Hide>
						<h1>What comes after sex?</h1>
					</Hide>
				)}
				<div className={`otp-container ${error ? "otp-container--error" : ""}`}>
					<OTPInput
						value={otp}
						onChange={setOtp}
						numInputs={7}
						renderSeparator={<span className="otp-seperator"></span>}
						renderInput={(props) => <input {...props} className="otp-input" />}
						shouldAutoFocus={true}
					/>
				</div>
				<div className="slider-container">
					<Slider onClick={handleSliderClick} isOpen={isOpen}>
						{isOpen ? (
							<div className="hi-bubs hi-bubs--open">Hi bubs!</div>
						) : (
							<div className="hi-bubs">Hmm...</div>
						)}
					</Slider>
				</div>
			</div>
		</div>
	);
};

export default Lock;
