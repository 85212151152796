import { useSpring, animated, config, useTrail, a } from "@react-spring/web";
import React from "react";
import { Animation } from "rsuite";
import TextGradientComponent from "./TextGradient";
import Hide from "./Hide";

export interface ButtonProps {
	children: string;
	variant?: "primary" | "secondary";
	shouldFlip?: boolean;
	flipText?: string;
	className?: string;
	invisible?: boolean;
	onClick?: () => void;
}

const bg1anim = [
	{ backgroundColor: "var(--step0)" },
	{ backgroundColor: "var(--step1)" },
	{ backgroundColor: "var(--step2)" },
	{ backgroundColor: "var(--step3)" },
	{ backgroundColor: "var(--step4)" },
];

const bg2anim = [
	{ backgroundColorSec: "var(--step1)" },
	{ backgroundColorSec: "var(--step0)" },
	{ backgroundColorSec: "var(--step4)" },
	{ backgroundColorSec: "var(--step2)" },
	{ backgroundColorSec: "var(--step3)" },
];

type Placement = "top" | "bottom" | "left" | "right";

const randomizeArray = (arr: any[]) => {
	const newArr = [...arr];
	for (let i = newArr.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[newArr[i], newArr[j]] = [newArr[j], newArr[i]];
	}
	return newArr;
};

const Button = ({
	children,
	variant = "primary",
	shouldFlip,
	flipText,
	invisible,
	onClick,
}: ButtonProps) => {
	const [show, setShow] = React.useState(false);
	const flipVariant = variant === "primary" ? "secondary" : "primary";

	if (shouldFlip && !flipText) {
		throw new Error("flipText is required when shouldFlip is true");
	}

	const [{ backgroundColor }] = useSpring(
		() => ({
			from: { backgroundColor: "var(--step0)" },
			to: randomizeArray(bg1anim),
			config: config.molasses,
			loop: {
				reverse: true,
			},
		}),
		[]
	);
	const [{ backgroundColorSec }] = useSpring(
		() => ({
			from: { backgroundColorSec: "var(--step0)" },
			to: randomizeArray(bg2anim),
			config: config.molasses,
			loop: {
				reverse: true,
			},
		}),
		[]
	);

	const getRandomPlacement = (): Placement => {
		const placements: Placement[] = ["top", "bottom", "left", "right"];
		const randomIndex = Math.floor(Math.random() * placements.length);

		return placements[randomIndex];
	};

	const getRandomDelayTime = (): number => {
		const maxDelayTimeInMs = 3000;

		return Math.floor(Math.random() * maxDelayTimeInMs);
	};

	const getRandomAnimationDuration = () => {
		const maxAnimationDuration = 5;
		const minimumAnimationDuration = 1;

		return (
			Math.random() * (maxAnimationDuration - minimumAnimationDuration) +
			minimumAnimationDuration
		);
	};

	if (shouldFlip) {
		return (
			<Animation.Slide
				className="scene"
				timeout={getRandomDelayTime()}
				placement={getRandomPlacement()}
				in={true}
			>
				{(props, ref) => (
					<div
						{...props}
						ref={ref}
						style={{ animationDuration: `${getRandomAnimationDuration()}s` }}
					>
						<div className="card">
							<div className="card__face card__face--front">
								<animated.button
									onClick={onClick}
									className={`button  button--${variant}`}
								>
									<Hide>
										<span>{children as string}</span>
									</Hide>
								</animated.button>
							</div>
							<div className="card__face card__face--back">
								<animated.button
									onClick={onClick}
									className={`button  button--${flipVariant}`}
									style={{
										backgroundColor: backgroundColorSec,
										borderColor: backgroundColor,
									}}
								>
									<Hide>
										<span>{flipText as string}</span>
									</Hide>
								</animated.button>
							</div>
						</div>
					</div>
				)}
			</Animation.Slide>
		);
	}

	return (
		<button
			onClick={onClick}
			className={`button  button--${variant} ${invisible && "invisible"}`}
		>
			<Hide>{children}</Hide>
		</button>
	);
};

export default Button;
