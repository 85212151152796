import React from "react";
import Envelope from "./Envelope";
import { IPanelProps } from "./types";
import { Trail } from "./Trail";
import Hide from "./Hide";

const LettersPanel = ({ nextPage, isActive }: IPanelProps) => {
	return (
		<div className="panel--inner panel--inner--yesno">
			<div className="title-container">
				<Trail open={isActive}>
					<Hide>
						<span style={{ fontSize: "80px" }}>Yay {"😊"}</span>
					</Hide>
				</Trail>
			</div>
			{isActive && <Envelope />}
			<div className="title-container">
				<Trail open={isActive}>
					<Hide>
						<span style={{ fontSize: "60px" }}>
							With that i leave you with this letter :)
						</span>
					</Hide>
				</Trail>
			</div>
		</div>
	);
};

export default LettersPanel;
