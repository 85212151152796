import React, { useState } from "react";
import Pages from "./Pages";

import "rsuite/dist/rsuite-no-reset.min.css";
import "./App.css";
import "./app.scss";
import "rsuite/Animation/styles/index.css";

function App() {
	const [lastChoice, setLastChoice] = useState(false);
	const [unlocked, setUnlocked] = useState(false);

	return <Pages />;
}

export default App;
