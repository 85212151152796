import React, { useState, useEffect } from "react";
import "./App.css"; // You may need to create App.css for styling
import { Trail } from "./Trail";
import YesComponent from "./yes-component";
import Hide from "./Hide";
import { IPanelProps } from "./types";

const steps = [
	{
		text: "Ok then!",
		emoji: "",
		length: 1,
	},
	{
		text: "I have one question!",
		emoji: "‼️",
		length: 1,
	},
	{
		text: "One question only!",
		emoji: "☝️",
		length: 1,
	},
	{
		text: "Will",
		emoji: "🤔",
		length: 1,
	},
	{
		text: "You",
		emoji: "😏",
		length: 1,
	},
	{
		text: "Bubi",
		emoji: "🥺",
		length: 1,
	},
	{
		text: "Be",
		emoji: "🤗",
		length: 1,
	},
	{
		text: "My",
		emoji: "😜",
		length: 1,
	},
	{
		text: "Valentine?",
		emoji: "😍",
		length: 1,
	},
	{
		text: "And let me be clear again :)",
		emoji: "",
		length: 3,
	},
];

const getRandomPosition = () => {
	const x = Math.random() * window.innerWidth;
	const y = Math.random() * window.innerHeight;
	return { x, y };
};

const AnimatedSentences = ({ nextPage, isActive }: IPanelProps) => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const [emojiPositions, setEmojiPositions] = useState<
		{ x: number; y: number }[]
	>([]);

	// useEffect(() => {
	// 	const interval = setInterval(() => {
	// 		setCurrentIndex((prevIndex) => prevIndex + 1);
	// 	}, 2000);

	// 	return () => clearInterval(interval);
	// }, []);

	useEffect(() => {
		if (currentIndex < steps.length) {
			const interval = setTimeout(() => {
				setCurrentIndex((prevIndex) => prevIndex + 1);
			}, 2000);

			return () => clearTimeout(interval);
		}
	}, [currentIndex]);

	useEffect(() => {
		if (currentIndex < steps.length) {
			const positions = Array.from({ length: 33 }, () => getRandomPosition());
			setEmojiPositions(positions);
		}
	}, [currentIndex]);

	return (
		<div className="animsen">
			{currentIndex < steps.length ? (
				<>
					<div className="title-container">
						{steps.map(
							(step, index) =>
								currentIndex === index && (
									<Trail open={currentIndex === index} key={index}>
										<Hide>
											<span
												style={{
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<span className="emojitext">{step.emoji}</span>
												<span style={{ margin: "0 10px" }}>{step.text}</span>
												<span className="emojitext">{step.emoji}</span>
											</span>
										</Hide>
									</Trail>
								)
						)}
					</div>

					{emojiPositions.map((position, index) => (
						<div
							key={index}
							className="emoji"
							style={{ top: position.y, left: position.x }}
						>
							{steps[currentIndex].emoji}
						</div>
					))}
				</>
			) : (
				<YesComponent onlyYes onClick={nextPage} />
			)}
		</div>
	);
};

export default AnimatedSentences;
