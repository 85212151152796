import React, { useEffect, useState } from "react";
import { useSpring, animated, config, useTrail, a } from "@react-spring/web";
import { Trail } from "./Trail";
import YesComponent from "./yes-component";
import TextGradientComponent from "./TextGradient";
import { IPanelProps } from "./types";
import Hide from "./Hide";

const YesNo = ({ nextPage, isActive }: IPanelProps) => {
	const [lastChoice, setLastChoice] = useState(false);
	const [unlocked, setUnlocked] = useState(false);
	const [currentChoice, setCurrentChoice] = useState(0);

	const getPadding = (value: number): string => {
		const toUse = value + 1;

		return `${toUse * 10}px ${toUse * 2 * 10}px`;
	};

	const [{ backgroundColor }] = useSpring(
		() => ({
			from: { backgroundColor: "var(--step0)" },
			to: [
				{ backgroundColor: "var(--step0)" },
				{ backgroundColor: "var(--step1)" },
				{ backgroundColor: "var(--step2)" },
				{ backgroundColor: "var(--step3)" },
				{ backgroundColor: "var(--step4)" },
			],
			config: config.molasses,
			loop: {
				reverse: true,
			},
		}),
		[]
	);
	const [{ backgroundColorSec }] = useSpring(
		() => ({
			from: { backgroundColorSec: "var(--step0)" },
			to: [
				{ backgroundColorSec: "var(--step1)" },
				{ backgroundColorSec: "var(--step0)" },
				{ backgroundColorSec: "var(--step4)" },
				{ backgroundColorSec: "var(--step2)" },
				{ backgroundColorSec: "var(--step3)" },
			],
			config: config.molasses,
			loop: {
				reverse: true,
			},
		}),
		[]
	);

	const [{ padding }, api] = useSpring(() => ({
		padding: getPadding(currentChoice),
	}));

	const buttonTexts = [
		"No",
		"Are you sure?",
		"Positive?",
		"100% sure?",
		"Not gonna think about it?",
		"You're missing out",
		"One last chance",
		"Ok one more",
		"Maybe two more",
		"I'll give you a cookie",
		"I'll give you a hug",
		"I'll give you a kiss",
		"I'll give you a puppy",
		"I'll give you a unicorn???",
		"I'll give you a dragon????",
		"I'll give you a spaceship?????",
		"I'll give you the universe???????",
		"I'll give you everything????????",
		"Ok then",
		"You leave me no choice",
	];

	const getButtonText = () => {
		return buttonTexts[currentChoice];
	};

	const handleYes = () => {
		nextPage();
	};

	const handleNo = () => {
		if (currentChoice === buttonTexts.length - 1) {
			setUnlocked(true);
			setLastChoice(true);

			return;
		}

		setCurrentChoice((prev) => prev + 1);
	};

	useEffect(() => {
		api.set(() => ({ padding: getPadding(currentChoice) }));
	}, [currentChoice]);

	return (
		<div className="panel--inner panel--inner--yesno">
			<div className="title-container">
				<Trail open={isActive}>
					<Hide>
						<span
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							I have
						</span>
					</Hide>
					<Hide>
						<span
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							Question for you
						</span>
					</Hide>
				</Trail>
			</div>

			<div className="button-yes-no-wrapper">
				{lastChoice && <YesComponent onClick={handleYes} />}
				{!lastChoice && (
					<>
						<animated.button
							onClick={handleYes}
							style={{
								backgroundColor,
								borderColor: backgroundColorSec,
								padding,
							}}
							className="button  button--secondary button--yesno"
						>
							<Hide>Yes</Hide>
						</animated.button>
						<animated.button
							onClick={handleNo}
							style={{
								backgroundColor: backgroundColorSec,
								borderColor: backgroundColor,
							}}
							className="button  button--primary button--yesno"
						>
							<Hide>{getButtonText()}</Hide>
						</animated.button>
					</>
				)}
			</div>

			<div className="title-container">
				<Trail open={isActive}>
					<Hide>
						<span
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							Would you
						</span>
					</Hide>
					<Hide>
						<span
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							Like to
						</span>
					</Hide>
					<Hide>
						<span
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							Hear it? :)
						</span>
					</Hide>
				</Trail>
			</div>
		</div>
	);
};

export default YesNo;
