import React from "react";
import Button from "./button";

const YesComponent = ({ onClick, onlyYes }: any) => {
	const noText = onlyYes ? "Yes" : "No";

	return (
		<div className="yes-button">
			<div className="word-letter word-letter--Y">
				<Button
					onClick={onClick}
					className="button  button--primary"
					shouldFlip
					flipText="Yes"
				>
					{noText}
				</Button>
				<Button onClick={onClick} className="button  button--primary" invisible>
					Yes
				</Button>
				<Button
					onClick={onClick}
					className="button  button--primary"
					shouldFlip
					flipText="Yes"
				>
					{noText}
				</Button>
				<Button
					onClick={onClick}
					className="button  button--primary"
					shouldFlip
					flipText="Yes"
				>
					{noText}
				</Button>
				<Button onClick={onClick} className="button  button--primary" invisible>
					Yes
				</Button>
				<Button
					onClick={onClick}
					className="button  button--primary"
					shouldFlip
					flipText="Yes"
				>
					{noText}
				</Button>
				<Button
					onClick={onClick}
					className="button  button--primary"
					shouldFlip
					flipText="Yes"
				>
					{noText}
				</Button>
				<Button
					onClick={onClick}
					className="button  button--primary"
					shouldFlip
					flipText="Yes"
				>
					{noText}
				</Button>
				<Button
					onClick={onClick}
					className="button  button--primary"
					shouldFlip
					flipText="Yes"
				>
					{noText}
				</Button>
				<Button onClick={onClick} className="button  button--primary" invisible>
					Yes
				</Button>
				<Button
					onClick={onClick}
					className="button  button--primary"
					shouldFlip
					flipText="Yes"
				>
					{noText}
				</Button>
				<Button onClick={onClick} className="button  button--primary" invisible>
					Yes
				</Button>
				<Button onClick={onClick} className="button  button--primary" invisible>
					Yes
				</Button>
				<Button
					onClick={onClick}
					className="button  button--primary"
					shouldFlip
					flipText="Yes"
				>
					{noText}
				</Button>
				<Button onClick={onClick} className="button  button--primary" invisible>
					Yes
				</Button>
			</div>
			<div className="word-letter word-letter--E">
				<Button
					onClick={onClick}
					className="button  button--primary"
					shouldFlip
					flipText="Yes"
				>
					{noText}
				</Button>
				<Button
					onClick={onClick}
					className="button  button--primary"
					shouldFlip
					flipText="Yes"
				>
					{noText}
				</Button>
				<Button
					onClick={onClick}
					className="button  button--primary"
					shouldFlip
					flipText="Yes"
				>
					{noText}
				</Button>
				<Button
					onClick={onClick}
					className="button  button--primary"
					shouldFlip
					flipText="Yes"
				>
					{noText}
				</Button>
				<Button onClick={onClick} className="button  button--primary" invisible>
					Yes
				</Button>
				<Button onClick={onClick} className="button  button--primary" invisible>
					Yes
				</Button>
				<Button
					onClick={onClick}
					className="button  button--primary"
					shouldFlip
					flipText="Yes"
				>
					{noText}
				</Button>
				<Button
					onClick={onClick}
					className="button  button--primary"
					shouldFlip
					flipText="Yes"
				>
					{noText}
				</Button>
				<Button onClick={onClick} className="button  button--primary" invisible>
					Yes
				</Button>
				<Button
					onClick={onClick}
					className="button  button--primary"
					shouldFlip
					flipText="Yes"
				>
					{noText}
				</Button>
				<Button onClick={onClick} className="button  button--primary" invisible>
					Yes
				</Button>
				<Button onClick={onClick} className="button  button--primary" invisible>
					Yes
				</Button>
				<Button
					onClick={onClick}
					className="button  button--primary"
					shouldFlip
					flipText="Yes"
				>
					{noText}
				</Button>
				<Button
					onClick={onClick}
					className="button  button--primary"
					shouldFlip
					flipText="Yes"
				>
					{noText}
				</Button>
				<Button
					onClick={onClick}
					className="button  button--primary"
					shouldFlip
					flipText="Yes"
				>
					{noText}
				</Button>
			</div>
			<div className="word-letter word-letter--S">
				<Button
					onClick={onClick}
					className="button  button--primary"
					shouldFlip
					flipText="Yes"
				>
					{noText}
				</Button>
				<Button
					onClick={onClick}
					className="button  button--primary"
					shouldFlip
					flipText="Yes"
				>
					{noText}
				</Button>
				<Button
					onClick={onClick}
					className="button  button--primary"
					shouldFlip
					flipText="Yes"
				>
					{noText}
				</Button>
				<Button
					onClick={onClick}
					className="button  button--primary"
					shouldFlip
					flipText="Yes"
				>
					{noText}
				</Button>
				<Button onClick={onClick} className="button  button--primary" invisible>
					Yes
				</Button>
				<Button onClick={onClick} className="button  button--primary" invisible>
					Yes
				</Button>
				<Button
					onClick={onClick}
					className="button  button--primary"
					shouldFlip
					flipText="Yes"
				>
					{noText}
				</Button>
				<Button
					onClick={onClick}
					className="button  button--primary"
					shouldFlip
					flipText="Yes"
				>
					{noText}
				</Button>
				<Button
					onClick={onClick}
					className="button  button--primary"
					shouldFlip
					flipText="Yes"
				>
					{noText}
				</Button>
				<Button onClick={onClick} className="button  button--primary" invisible>
					Yes
				</Button>
				<Button onClick={onClick} className="button  button--primary" invisible>
					Yes
				</Button>
				<Button
					onClick={onClick}
					className="button  button--primary"
					shouldFlip
					flipText="Yes"
				>
					{noText}
				</Button>
				<Button
					onClick={onClick}
					className="button  button--primary"
					shouldFlip
					flipText="Yes"
				>
					{noText}
				</Button>
				<Button
					onClick={onClick}
					className="button  button--primary"
					shouldFlip
					flipText="Yes"
				>
					{noText}
				</Button>
				<Button
					onClick={onClick}
					className="button  button--primary"
					shouldFlip
					flipText="Yes"
				>
					{noText}
				</Button>
			</div>
		</div>
	);
};

export default YesComponent;
