import React, { useEffect, useRef, useState } from "react";
import { useSpring, animated } from "@react-spring/web";
import "./App.css"; // You may need to create App.css for styling
import { useDrag } from "react-use-gesture";

const LineWithDots = ({
	currentPos,
	setPos,
	setShowNext,
}: {
	currentPos: number;
	setPos: (pos: number) => void;
	setShowNext: () => void;
}) => {
	const [dragging, setDragging] = useState(false);
	const [dotPositions, setDotPositions] = useState<
		{ left: number; top: number }[]
	>([]);
	const adjustXPosForBallWidth = (pos: number) => {
		return pos - 15;
	};

	const [ballPosition, setBallPosition] = useState(adjustXPosForBallWidth(0));

	const lineRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (lineRef.current) {
			const lineRect = lineRef.current.getBoundingClientRect();
			const dotWidth = lineRect.width / 4;
			const positions = Array.from({ length: 4 }, (_, index) => ({
				left: index * dotWidth - 15,
				top: lineRect.height / 2,
			}));
			positions.push({
				left: lineRect.width - 15,
				top: lineRect.height / 2,
			});
			setDotPositions(positions);
		}
	}, []);

	const [{ x, scale, clipPath }, api] = useSpring(() => ({
		x: ballPosition,
		scale: 1,
		background: `linear-gradient(to right, red ${ballPosition}%, orange ${
			ballPosition + 5
		}%, yellow ${ballPosition + 10}%, lightgreen ${ballPosition + 15}%, green ${
			ballPosition + 20
		}%)`,
		config: { tension: 120, friction: 14 },
		clipPath: `polygon(0 0, ${ballPosition}% 0, ${ballPosition}% 100%, 0% 100%)`,
	}));

	const getDotsXPosition = (): number[] => {
		return dotPositions.map(({ left }) => left);
	};

	const getNearestDotPosition = (
		realPos: number
	): { nearestPosition: number; positionIndex: number } => {
		const allPositions = getDotsXPosition();
		let nearestPosition = allPositions[0];
		let shortestDistance = Math.abs(realPos - allPositions[0]);
		let positionIndex = 0;

		for (const [index, pos] of allPositions.entries()) {
			if (Math.abs(realPos - pos) < shortestDistance) {
				shortestDistance = Math.abs(realPos - pos);
				nearestPosition = pos;
				positionIndex = index;
			}
		}

		return { nearestPosition, positionIndex };
	};

	const passFinal = (pos: number): boolean => {
		if (!lineRef.current) return false;

		const lineRect = lineRef.current.getBoundingClientRect();
		const dotWidth = lineRect.width / 4;
		const lastDot = dotPositions[dotPositions.length - 1].left;

		return pos > lastDot + dotWidth;
	};

	const bind = useDrag(({ active, movement: [xCur] }) => {
		if (!lineRef.current) return;

		const realPos = ballPosition + xCur;
		const lineRect = lineRef.current.getBoundingClientRect();

		if (!active && passFinal(realPos)) {
			const { nearestPosition, positionIndex } = getNearestDotPosition(realPos);
			setBallPosition(nearestPosition);
			setPos(positionIndex);
			setShowNext();

			return api.start({
				x: 5000,
				scale: 1.5,
				clipPath: `polygon(0 0, ${1000}% 0, ${1000}% 100%, 0% 100%)`,
				immediate: (name) => active && name === "x",
			});
		}

		if (!active) {
			const { nearestPosition, positionIndex } = getNearestDotPosition(realPos);
			setPos(positionIndex);
			setBallPosition(nearestPosition);

			const clipWidth = (nearestPosition / lineRect.width) * 100;
			return api.start({
				x: adjustXPosForBallWidth(nearestPosition),
				scale: 1,
				clipPath: `polygon(0 0, ${clipWidth}% 0, ${clipWidth}% 100%, 0% 100%)`,
				immediate: (name) => active && name === "x",
			});
		}

		const { positionIndex } = getNearestDotPosition(realPos);
		const clipWidth = (realPos / lineRect.width) * 100;
		setPos(positionIndex);
		return api.start({
			x: adjustXPosForBallWidth(realPos),
			scale: active ? 1.1 : 1,
			clipPath: `polygon(0 0, ${clipWidth}% 0, ${clipWidth}% 100%, 0% 100%)`,
			immediate: (name) => active && name === "x",
		});
	});

	const avSize = x.to({
		map: Math.abs,
		range: [50, 300],
		output: [0.5, 1],
		extrapolate: "clamp",
	});

	return (
		<div
			className="line"
			ref={lineRef}
			// onMouseMove={handleMouseMove}
			// onMouseUp={handleMouseUp}
		>
			<animated.div
				className="line-inner gradient-div"
				style={{
					clipPath,
				}}
			></animated.div>
			<div className="dots-container">
				{dotPositions.map((position, index) => (
					<animated.div
						key={index}
						className="dot"
						style={{ x: position.left }}
					>
						{" "}
					</animated.div>
				))}
			</div>
			<animated.div
				{...bind()}
				className="ball"
				// style={{ ...ballSpring, cursor: dragging ? "grabbing" : "grab" }}
				style={{ x, scale, y: -25 }}
			></animated.div>
		</div>
	);
};

export default LineWithDots;
