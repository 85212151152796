import React, { ReactNode, useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";
import { useDrag, useScroll, useWheel } from "react-use-gesture";
import styles from "./slide.module.css";
import Lock from "./Lock";
import { ReactElement } from "react";
import YesNo from "./YesNo";
import HMDILY from "./HMDILY";
import LettersPanel from "./LettersPanel";
import WYBMV from "./WYBMV";

export type ReactComponent = (props: any) => ReactElement;

const pages = [
	{
		id: 0,
		content: Lock,
	},
	{
		id: 1,
		content: YesNo,
	},
	{
		id: 2,
		content: HMDILY,
	},
	{
		id: 3,
		content: WYBMV,
	},
	{
		id: 4,
		content: LettersPanel,
	},
];

const INITIAL = 0;
const Pages = () => {
	const [isBlocked, setIsBlocked] = React.useState(false);
	const [page, setPage] = React.useState(INITIAL);
	const [maxPage, setMaxPage] = React.useState(INITIAL);
	const [activePage, setactivePage] = React.useState(INITIAL);

	const nextPage = () => {
		if (page < pages.length - 1) {
			setPage((prev) => prev + 1);
		}
	};

	useEffect(() => {
		if (page > maxPage) {
			setMaxPage(page);
		}
		setTimeout(() => {
			setactivePage(page);
		}, 500);
	}, [page]);

	const springProps = useSpring({
		transform: `translateY(-${page * 100}vh)`, // Translate Y position
	});

	const isUp = (y: number) => {
		return y < 0;
	};

	const isDown = (y: number) => {
		return y > 0;
	};

	const handleChangePage = (yDirection: number, isScroll?: boolean) => {
		if (isUp(yDirection) && page > 0) {
			setPage((prev) => prev - 1);
		}

		if (
			isDown(yDirection) &&
			page < pages.length - 1 &&
			(!isScroll || maxPage > page)
		) {
			setPage((prev) => prev + 1);
		}
	};

	const bind = useWheel((state) => {
		const [, yDirection] = state.direction;

		if (isBlocked) {
			return;
		}
		setIsBlocked(true);
		handleChangePage(yDirection, true);

		setTimeout(() => {
			setIsBlocked(false);
		}, 1000);
	});

	return (
		<main className="app">
			<animated.div {...bind()} className="app--inner" style={springProps}>
				{pages.map((pagecom, index) => {
					const PageComponent: ReactComponent = pagecom.content;

					return (
						<div className="panel" key={`panel-${pagecom.id}`}>
							<PageComponent
								nextPage={nextPage}
								isActive={activePage === pagecom.id}
							/>
						</div>
					);
				})}
			</animated.div>
		</main>
	);
};

export default Pages;
