import React, { useState } from "react";
import { IPanelProps } from "./types";
import LineWithDots from "./SliderBall";
import Hide from "./Hide";
import { Trail } from "./Trail";
import { Slider } from "./Slide";

const HMDILY = ({ nextPage, isActive }: IPanelProps) => {
	const [currentPos, setcurrentpos] = useState(0);
	const [showNext, setShowNext] = useState(false);

	const handlePosChange = (pos: number) => {
		setcurrentpos(pos);
	};

	const handleClick = () => {
		if (showNext) {
			nextPage();
		}
	};

	const positionMap = {
		0: { emoji: "What??? 😵" },
		1: { emoji: "Hmm... 😕" },
		2: { emoji: "Not quite... 😐" },
		3: { emoji: "Well... 🙂" },
		4: { emoji: "Almost there... 😊" },
	};

	const getEmoji = () => {
		return positionMap[currentPos as keyof typeof positionMap].emoji;
	};

	const handleShowNext = () => {
		setShowNext(true);
	};

	return (
		<div className="panel--inner panel--inner--yesno">
			<div className="title-container">
				<Trail open={isActive}>
					<Hide>
						<span style={{ fontSize: "70px" }}>
							First! How much do you love me?
						</span>
					</Hide>
				</Trail>
			</div>
			<div className="container spread">
				<div className="title-container">
					{showNext ? <h2>Exactly!!! 😌</h2> : <h2>{getEmoji()}</h2>}
				</div>

				<LineWithDots
					setShowNext={handleShowNext}
					currentPos={currentPos}
					setPos={handlePosChange}
				/>
			</div>
			<div className="title-container">
				<Slider onClick={handleClick} isOpen={showNext}>
					{showNext ? (
						<span className="pass-button">Ok then answer me this</span>
					) : (
						<span className="pass-button">You shall not pass</span>
					)}
				</Slider>
			</div>
		</div>
	);
};

export default HMDILY;
