import React from "react";
import { IPanelProps } from "./types";
import { Trail } from "./Trail";
import Hide from "./Hide";
import AnimatedSentences from "./AnimatedSentences";

const WYBMV = ({ nextPage, isActive }: IPanelProps) => {
	return (
		<div className="panel--inner ">
			{isActive && (
				<AnimatedSentences isActive={isActive} nextPage={nextPage} />
			)}
		</div>
	);
};

export default WYBMV;
